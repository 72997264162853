import React from 'react'

export interface Props {
  children: React.ReactNode;
  className?: string;
  id?: string;
  maxWidth?: string;
}

export function Text({
  children,
  className,
  id,
  maxWidth
}: Props) {
  return (
    <p
      id={id}
      className={`${className}`}
      style={{
        width: maxWidth
      }}
    >
      {children}
    </p>
  )
}
