import React from 'react'
import RegularButton from './atoms/buttons/RegularButton'
import { Text } from './atoms/texts/Text'

import './startProgramBanner.scss'

interface Props {
    bgColor?: string
}

export const StartProgramBanner = ({bgColor}: Props) => {
    const sendToAppTheia = () => {
        location.href = 'https://app.theia.com.br'
    }
    return (
        <div className={`banner-div flex justify-center ${bgColor ? `bg-${bgColor}` : ''}`}>
            <div className="content-div flex items-center justify-center flex-col text-center">
                <Text className="text-titleMedium font-normal text-mediumPurpleColor mb-12 md:text-dsTitle2">
                    Comece agora seu programa de acompanhamento da gravidez semana a semana:
                </Text>
                <RegularButton
                    label="Começar programa"
                    maxWidth="360px"
                    extraClass="focus:outline-none"
                    onClick={sendToAppTheia}
                />
            </div>
        </div>
    )
}