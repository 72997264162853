import React, { useEffect } from "react"
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import { AnchorLink } from "gatsby-plugin-anchor-links"

import Layout from "../components/layout"
import './gravidez.scss'

import ObstetraImg from '../images/ilustra-gravidez5.png'
import IlustraGravidez from '../images/ilustra-reembolso1.png'
import IlustraGravidezThird from '../images/ilustra-reembolso2.png'
import trackEvent from "../analyticsHelpers"
import { FormReembolso } from '../components/formReembolso'
import { StartProgramBanner } from "../components/startProgramBanner"

const Reembolso = () => {
  useEffect(() => {
    trackEvent("sitenovo-guia-de-reembolso-visualizou-tela")
  }, [])

  const redirectButton = () => {
    trackEvent("sitenovo-guia-de-reembolso-clicou-botao-receber-contato-theia")
  }
  return (
    <Layout>
      <GatsbySeo
        title='Theia | Como pedir reembolso de pré-natal e parto com o plano de saúde'
        description='.'
        language='pt-br'
        canonical='https://blog.theia.com.br/guia-de-reembolso'
        openGraph={{
          type: 'website',
          url: 'https://blog.theia.com.br/guia-de-reembolso',
          title: 'Theia | Como pedir reembolso de pré-natal e parto com o plano de saúde',
          description: 'Quer fazer acompanhamento pré-natal e parto particular, mas não sabe como fazer reembolso com seu plano de saúde? Criamos esse guia para te ajudar a fazer reembolso de parto e pré-natal com seu plano de saúde.',
          images: [
            {
              url: 'https://theia.com.br/theia.png',
              width: 250,
              height: 367,
              alt: 'Og Imagem Alt',
            },
          ],
        }}
        metaTags={[{
          name: 'dc:title',
          content: 'Theia | Como pedir reembolso de pré-natal e parto com o plano de saúde'
        }, {
          name: 'dc:description',
          content: 'Quer fazer acompanhamento pré-natal e parto particular, mas não sabe como fazer reembolso com seu plano de saúde? Criamos esse guia para te ajudar a fazer reembolso de parto e pré-natal com seu plano de saúde.'
        }, {
          name: 'dc:language',
          content: 'BR',
        }, {
          name: 'reply-to',
          content: 'suporte@theia.com.br',
        }, {
          name: 'author',
          content: 'Theia',
        }, {
          name: 'description',
          content: 'Quer fazer acompanhamento pré-natal e parto particular, mas não sabe como fazer reembolso com seu plano de saúde? Criamos esse guia para te ajudar a fazer reembolso de parto e pré-natal com seu plano de saúde.',
        },
         {
          name: 'keywords',
          content: 'gravidez, sintomas de gravidez, testes de gravidez, ginecologistas, obstetras, Fisioterapeuta Pelvica, psicologos, fisioterapeuta pelvico, preparador fisico, pré-natal, pre natal, semanas de gestacao, ensaio gestante, exame de gravidez, mulher gravida, ginecologistas unimed,semanas gestacionais, gravidez ectopica, consulta pre natal, consulta pré natal, parto, períneo, parto normal, parto humanizado, puerpério, cesaria, cesariana, parto cesaria, dentista, atendimento odontológico na gestação, parto cesária',
        },
        , {
          property: 'twitter:card',
          content: 'summary_large_image',
        }
      ]}
      />
      <section className="wrapper-section-row gravidez relative">
        <h1 className="text-titleMedium lg:text-titleExtraLarge font-medium text-darkPurpleColor item1 item1-engravidar">GUIA INFORMATIVO PARA REEMBOLSO DE PLANO DE SAÚDE (“Guia”)</h1>
        <span className="block item1 text-darkGreyColor"><b>Data da última atualização:</b> 07 de dezembro de 2020</span>
        <div className="item2 font-light lg:text-titleMedium text-textSmall text-darkGreyColor">
          <p>
            Uma dúvida muito comum que as pessoas têm durante a gestação (seja sua ou de alguém próxima) é sobre a possibilidade dos planos privados de assistência à saúde (“Plano” ou “Planos”) reembolsarem o parto, dado que é possível que nem todos os planos de saúde cubram os custos da equipe de parto, e estes custos, na rede particular, podem ser muito elevados.
          </p>
          <p>
            Pensando nisso, nós, da Theia, resolvemos criar este Guia para auxiliar na sua decisão de reembolso. Ressaltamos, antes de entrar no Guia propriamente dito, algumas coisas:
          </p>
          <p>
            <b>I.</b> Este é um Guia genérico, de caráter meramente orientativo. Como o processo pode variar de plano para plano, infelizmente alguns casos não estão aqui contemplados. Esperamos que este Guia te ajude!
          </p>
          <p>
            <b>II.</b> Focamos aqui em reembolso de equipe de parto. Não estamos considerando os eventuais custos de estrutura e custos hospitalares;
          </p>
          <p>
            <b>III.</b> Confirme o valor dos reembolsos de cada categoria de seu Plano com antecedência. Evite surpresas, gastos não previstos e estresses desnecessários na época do parto. É muito importante também que a equipe de parto esteja definida previamente;
          </p>
          <p>
            <b>IV.</b> O reembolso precisa ser solicitado por você junto ao plano, tendo sido o parto contratado com a equipe indicada pela Theia ou não. Inclusive, os valores e disponibilidades do parto com a equipe indicada pela Theia podem variar, conforme descrito nos nossos Termos de Uso <a className="underline text-mediumPurpleColor" href="/terms">(www.theia.com.br/terms)</a>;
          </p>
          <p>
            <b>V.</b> Este Guia pode ser atualizado, e o faremos sempre que decidirmos alterar alguma coisinha nele, sempre pensando em trazer informações mais claras e abrangentes à você. Assim, sugerimos que você consulte este Guia sempre que tiver dúvidas, e poderá acompanhar a data da última atualização aqui, na primeira página.
          </p>
          <p>
            Caso tenha quaisquer outras dúvidas, questionamentos ou elogios a respeito deste guia, não hesite em nos contatar via chat dentro da Plataforma ou mandar um e-mail para o endereço ola@theia.com.br. 
          </p>
          <p>å
            Bom, agora podemos realmente falar do Guia. Para ficar mais fluida e fácil a leitura, vamos dividir o guia em duas partes. A primeira trará algumas respostas rápidas para perguntas frequentes, e a segunda será focada nas simulações de reembolso de parto. 
          </p>
        </div>
        <div className="item3 reembolso-ilustra1">
          <img alt="ilustração gravidez 1" src={IlustraGravidez} />
        </div>
      </section>
      <section className="wrapper-section-row faq parto-normal">
        <h2 className="item1 text-center text-darkPurpleColor text-titleMedium lg:text-titleLarge font-medium reembolso">Perguntas frequentes</h2>
        <p className="text-darkGreyColor lg:text-titleMedium text-textMedium font-light item4 text-center">Vamos listar algumas informações que devem ser confirmadas junto ao seu Plano, mas que são válidas na grande maioria deles:</p>
        <div className="item2">
          <div className="faq-questions">
            <p>
              <b>1.</b> Os Planos não têm a obrigatoriedade do reembolso, então confirme se o seu reembolsa despesas;
            </p>
          </div>
          <div className="faq-questions">
            <p>
              <b>2.</b> O valor do reembolso possui um limite máximo por categoria, não sendo, então, um valor percentual;
            </p>
          </div>
          <div className="faq-questions">
            <p>
              <b>3.</b> O total a ser reembolsado pode ser, em alguns casos, comunicado mediante telefonema ou pelo website do seu Plano. Entretanto, no caso do parto, devido ao grande número de categorias a serem reembolsadas, é mais provável que você só consiga esta informação mediante simulação no website ou no aplicativo do Plano; 
            </p>
          </div>
          <div className="faq-questions">
            <p>
              <b>4.</b> Em linhas gerais, se o seu Plano permite reembolsos de consultas de especialistas não conveniados, é muito provável que você tenha direito a reembolsos, mesmo que parciais, das despesas da equipe médica do parto (confira com antecedência, ok?);
            </p>
          </div>
          <div className="faq-questions">
            <p>
              <b>5.</b> Diferentemente do reembolso de consultas, que, em alguns casos, simplesmente a apresentação da Nota Fiscal de Serviços (“NF”) com os dados do médico são suficientes, para o parto, é possível que, além da NF de cada categoria, seja solicitado o relatório do parto, partograma e outros documentos descrevendo o parto;
            </p>
          </div>
        </div>
        <div className="item3">
          <div className="faq-questions">
            <p>
              <b>6.</b> Programe-se (pedimos desculpas pela repetição, mas é importante): as NFs para reembolso são emitidas (salvas raríssimas exceções) apenas após o parto, então eventualmente será necessário um desembolso de dinheiro, que retornará apenas mais para frente;
            </p>
          </div>
          <div className="faq-questions">
            <p>
              <b>7.</b> O prazo de reembolso, geralmente, é de 30 (trinta) dias após a apresentação da documentação correta ao seu Plano;
            </p>
          </div>
          <div className="faq-questions">
            <p>
              <b>8.</b> É possível que o Plano tenha um valor adicional de reembolso caso o parto ocorra entre às 22h e às 6h da manhã (novamente, confirme, não há obrigatoriedade nenhuma disso);
            </p>
          </div>
          <div className="faq-questions">
            <p>
              <b>9.</b> Pode acontecer de o valor final reembolsado ser diferente do valor simulado na prévia (tanto para mais quanto para menos);
            </p>
          </div>
          <div className="faq-questions">
            <p>
              <b>10.</b> Após o parecer do Plano (e após pagamento também), é possível ainda contestar os valores do reembolso. É comum que sua interpretação divirja da do Plano, e pode ser que algum procedimento ou categoria não tenha sido reembolsado. Nestes casos, é possível entrar com uma reclamação junto à Agência Nacional de Saúde Suplementar (ANS) para a resolução do conflito, sem que seja necessário, neste primeiro momento, envolvimento judicial.
            </p>
          </div>
        </div>
      </section>
      <section className="wrapper-section-row engravidar third reembolso-section relative">
       
        <div className="item2 font-light lg:text-titleMedium text-textSmall text-darkGreyColor">
          <h2 className="item1 text-textMedium lg:text-titleLarge font-medium text-darkPurpleColor tentativas">
            SIMULAÇÕES DE REEMBOLSO DE PARTO
          </h2>
          <p>
            Explicados alguns dos pontos mais comuns de dúvidas, vamos passar agora para outra parte, que é um detalhamento do que, no geral, é preciso para solicitar uma prévia de reembolso junto ao Plano. Citaremos alguns códigos e termos médicos e queremos explicá-los antes que gerem dúvidas:
          </p>
          <div className="item4">
            <img alt="ilustração gravidez 3" src={IlustraGravidezThird} />
          </div>
          <p>
            <b>• CID ou CID-10</b> - é a Classificação Internacional de Doenças e Problemas Relacionados à Saúde. Você verá que os códigos que citaremos não tem nada de Doenças ou Problemas de Saúde, mas ok, não fomos nós que escolhemos essa denominação;
          </p>
          <p>
            <b>• TUSS</b> - é a sigla para Terminologia Unificada da Saúde Suplementar, que, basicamente, padroniza as nomenclaturas e códigos dos procedimentos médicos. Assim, há melhor comunicação entre os Planos e os médicos.
          </p>
          <p>
            Pronto, vamos às principais informações que o Plano pode te pedir, para que você consiga simular uma prévia de reembolso (lembrando que é a apenas uma prévia, e este valor pode variar para mais ou para menos):
          </p>
          <p>
            <b>1. Tipo de parto:.</b> cesárea e parto normal possuem valores e complexidades diferentes;
          </p>
          <p>
            <b>2. Data esperada do parto:</b> apesar de não ser uma data precisa, ela é considerada pois podem haver variação de custos, reembolsos e reajustes até a data;
          </p>
          <p>
            <b>3. Hospital previsto do parto:</b> apesar de o foco aqui ser na equipe do parto, é possível que o Plano pergunte (há planos que tem restrição geográfica, por exemplo, e isso impacta na equipe);
          </p>
          <p>
            <b>4 .Número de fetos:</b> é natural considerar (mesmo que na prática pode ser diferente) que o parto de um único bebê deve ser menos complexo que o de gêmeos, trigêmeos ou mais;
          </p>
          <p>
            <b>5. CIDs:</b> não é comum, mas é possível que os Planos peçam, vamos citar apenas os mais recorrentes:
            <span className="block">a) Z34.9 – Supervisão de gravidez normal </span>
            <span className="block">b) O80.0 – Parto único espontâneo cefálico </span>
            <span className="block">c) Z39.0 – Assistência e exame imediatamente após o parto</span>
          </p>
        </div>
        <div className="item2 bigger-content font-light lg:text-titleMedium text-textSmall text-darkGreyColor">
          <p>
            <b>6. Valores individualizados de cada profissional:</b> no geral, os profissionais envolvidos no parto são: cirurgiã (a própria obstetra), auxiliar de cirurgia, instrumentador e anestesista;
          </p>
          <p>
            <b>7. TUSS:</b> não é comum, mas não é impossível que seu Plano solicite. Seguem os mais recorrentes:
            <span className="block">
              a) Obstetra: 3130909-7: Maturação cervical para indução 
            </span>
            <span className="block">
              b) Obstetra: 3130903-8: Assistência ao trabalho de parto por hora (limite máximo de 6 horas) 
            </span>
            <span className="block">
              c) Obstetra: 3130912-7: Parto normal 
            </span>
            <span className="block">
              d) Obstetra: 3130905-4: Cesariana 
            </span>
            <span className="block">
              e) Obstetra: 1010201-9: Visita hospitalar 
            </span>
            <span className="block">
              f) Anestesista: 3160222-3: Analgesia para parto normal 
            </span>
            <span className="block">
              g) Anestesista: 3160206-1: Anestesia para cesariana
            </span>
          </p>
          <p>
            <b>8. Outras informações:</b> este último item não será solicitado pelo Plano para a prévia, mas é importante você ter em mente estas informações:
            <span className="block">
              a) Não consideramos aqui o pediatra de sala de parto e o pediatra que acompanhará o seu bebê durante os dias que estiverem no hospital . Apesar de ser uma figura presente, geralmente é utilizado o pediatra da equipe do hospital que estará de plantão, e o valor do pediatra é incluído no valor de internação/infraestrutura do hospital;
            </span>
            <span className="block">
              b) É possível que o Plano de saúde te retorne, após solicitação da prévia, valores referentes à hora de trabalho da equipe, e não um valor cheio, dado que não há como prever a duração do trabalho de parto e do parto em si.
            </span>
          </p>
          <p>Este Guia te ajudou? Ficou claro? Conseguimos responder às suas dúvidas? Gostaríamos de te ouvir, então nos contate via chat ou no e-mail <a className="underline text-mediumPurpleColor" href="mailto:ola@theia.com.br">ola@theia.com.br</a>. </p>
        </div>
      </section>
      <section className="flex justify-center start-program-section">
          <StartProgramBanner />
      </section>
    </Layout>
  )
}

export default Reembolso